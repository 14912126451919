<template>
    <div>
        <TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
            :slot_table_list="['operation','status']" ref="list" :rowKey="'id'" >
            <template slot="left_btn">
                <a-button @click="getAddSave('','add')" type="primary">新增工厂</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.record.status == 1" class="status_box" @click="getMaterialStatus(data.record)">启用</span>
                <span v-else class="status_box" style="background:red;" @click="getMaterialStatus(data.record)">禁用</span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="getAddSave(data.record,'edit')">编辑</a>
                <a-divider type="vertical" />
                <a @click="getDelete(data.record)">删除</a>
            </template>
        </TableList>

        <!-- 添加或编辑工厂 -->
        <a-modal v-model:visible="factoryShow" :title="factoryFrom.id ? '编辑工厂信息' : '新增工厂信息'" @ok="factoryOk">
            <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
                <a-form-item required name='name' label="工厂名称" :rules="[{ required: true, message: '工厂名称不能为空',tagger:'blur' }]">
                    <a-input v-model:value="factoryFrom.factory_name" placeholder="请输入工厂名称" />
                </a-form-item>
                <a-form-item required name='name' label="工厂编号" :rules="[{ required: true, message: '工厂编号不能为空',tagger:'blur' }]">
                    <a-input v-model:value="factoryFrom.factory_code" placeholder="请输入工厂编号" />
                </a-form-item>
				<a-form-item required name='name' label="工厂ID" :rules="[{ required: true, message: '工厂编号不能为空',tagger:'blur' }]">
				    <a-input v-model:value="factoryFrom.drp_id" placeholder="请输入工厂ID" />
				</a-form-item>
                <a-form-item required name='name' label="工厂负责人">
                    <span class="manage_main" v-for="(item,index) in factoryAll" :key="index">
                        <span class="manage_user">
                            {{ item.username }}
                            <img src="../../../assets/img/icon_delete.png" @click="manage_delete(index,1)"/>
                        </span>
                    </span>
                    <a @click="add_manage(1)" style="display:inline-block;">选择工厂负责人</a>
                </a-form-item>
                <a-form-item required name='name' label="品控负责人">
                    <span class="manage_main" v-for="(item,index) in qualityAll" :key="index">
                        <span class="manage_user">
                            {{ item.username }}
                            <img src="../../../assets/img/icon_delete.png" @click="manage_delete(index,2)"/>
                        </span>
                    </span>
                    <a @click="add_manage(2)" style="display:inline-block;">选择品控负责人</a>
                </a-form-item>
                <a-form-item name='name' label="生产负责人">
                    <span class="manage_main" v-for="(item,index) in produceAll" :key="index">
                        <span class="manage_user">
                            {{ item.username }}
                            <img src="../../../assets/img/icon_delete.png" @click="manage_delete(index,3)"/>
                        </span>
                    </span>
                    <a @click="add_manage(3)" style="display:inline-block;">选择生产负责人</a>
                </a-form-item>
                <a-form-item name='name' label="工厂财务">
                    <span class="manage_main" v-for="(item,index) in financeAll" :key="index">
                        <span class="manage_user">
                            {{ item.username }}
                            <img src="../../../assets/img/icon_delete.png" @click="manage_delete(index,4)"/>
                        </span>
                    </span>
                    <a @click="add_manage(4)" style="display:inline-block;">选择工厂财务</a>
                </a-form-item>
				
				<a-form-item  name='name' label="仓库负责人">
					<!-- warehouse_director -->
				    <span class="manage_main" v-for="(item,index) in warehouseAll" :key="index">
				        <span class="manage_user">
				            {{ item.username }}
				            <img src="../../../assets/img/icon_delete.png" @click="manage_delete(index,5)"/>
				        </span>
				    </span>
				    <a @click="add_manage(5)" style="display:inline-block;">选择仓库负责人</a>
				</a-form-item>
            </a-form>
        </a-modal>
        <SelectUser @submit="submit_manage" :visible.sync="visible_manage" select_type="checkbox"></SelectUser>

    </div>
</template>
<script>
import Form from "@/components/Form"
import TableList from "@/components/TableList"
import SelectUser from "@/components/SelectUser";
import {message,Modal} from 'ant-design-vue'
import {getFactoryList,saveFactoryInfo,delFactoryInfo,changeFactoryStatus} from '@/api/qms.js'
const columns = [{
        title: "工厂名称",
        dataIndex: "factory_name",
    },
    {
        title: "工厂编码",
        dataIndex: "factory_code",
    },
	{
	    title: "工厂ID",
	    dataIndex: "drp_id",
	},
    {
        title: "工厂负责人",
        dataIndex: "director_name",
    },
    {
        title: "品控负责人",
        dataIndex: "quality_director_name",
    },
    {
        title: "生产负责人",
        dataIndex: "produce_director_name",
    },
    {
        title: "工厂财务",
        dataIndex: "finance_director_name",
    },
	{
	    title: "仓库负责人",
	    dataIndex: "warehouse_director_name",
	},
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

export default {
    name: "Index",
    components: {
        Form,
        TableList,
        SelectUser
    },
    data() {
        return {
            get_table_list: getFactoryList,
            columns,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: "请输入工厂名称",
                        options: {}
                    },
                ],
            },
            factoryShow:false,
            factoryFrom:{
                id:'',
                factory_name:'',
                factory_code:'',
                director:'',
                quality_director:'',
                produce_director:''
            },

            labelColEdit: {
                span: 5
            },
            wrapperColEdit: {
                span: 18
            },

            visible_manage:false,
            selectUserType:1,

            factoryAll:[],
            qualityAll:[],
            produceAll:[],
            financeAll:[],
			warehouseAll:[]
        };
    },
    created() {

    },
    methods: {
        getAddSave(info,type){
            //清空数据
            this.factoryFrom = {}
            this.factoryAll = []
            this.qualityAll = []
            this.produceAll = []
            this.financeAll = []
			this.warehouseAll = []
            //编辑数据
            if(type == 'edit'){
                let infoData = info
                this.factoryFrom.id = infoData.id
                this.factoryFrom.factory_name = infoData.factory_name
                this.factoryFrom.factory_code = infoData.factory_code
				this.factoryFrom.drp_id = infoData.drp_id
                this.factoryAll = infoData.director_data
                this.qualityAll = infoData.quality_director_data
                this.produceAll = infoData.produce_director_data
                this.financeAll = infoData.finance_director_data
				this.warehouseAll = infoData.warehouse_director_data
            }
            this.factoryShow = true
        },

        factoryOk(){
            //工厂负责人
            let director = []
            for(let i = 0; i < this.factoryAll.length; i++){
                director.push(this.factoryAll[i].id)
            }
            this.factoryFrom.director = director.length > 0 ? director.join(',') : ''
            //品控负责人
            let quality_director = []
            for(let i = 0; i < this.qualityAll.length; i++){
                quality_director.push(this.qualityAll[i].id)
            }
            this.factoryFrom.quality_director = quality_director.length > 0 ? quality_director.join(',') : ''
            //生产负责人
            let produce_director = []
            for(let i = 0; i < this.produceAll.length; i++){
                produce_director.push(this.produceAll[i].id)
            }
			this.factoryFrom.produce_director = produce_director.length > 0 ? produce_director.join(',') : ''
			//仓库负责人
			let warehouse_director = []
			for(let i = 0; i < this.warehouseAll.length; i++){
			    warehouse_director.push(this.warehouseAll[i].id)
			}
			this.factoryFrom.warehouse_director = warehouse_director.length > 0 ? warehouse_director.join(',') : ''
			// 
            let finance_director = []
            for(let i = 0; i< this.financeAll.length; i++){
                finance_director.push(this.financeAll[i].id)
            }
            this.factoryFrom.finance_director = finance_director.length > 0 ? finance_director.join(',') : ''
            //提交数据
            saveFactoryInfo({
                ...this.factoryFrom
            }).then(res=>{
                this.factoryShow = false
                message.success('保存成功');
                this.$refs.list.get_list()
            })
        },

        getDelete(info){
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要删除工厂【'+info.factory_name+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    delFactoryInfo({
                        id:info.id
                    }).then(res=>{
                        message.success('删除成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },

        getMaterialStatus(info){
            let text = info.status == 1 ? "禁用" : "启用"
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要'+text+'工厂【'+info.factory_name+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    changeFactoryStatus({
                        id:info.id
                    }).then(res=>{
                        message.success('操作成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },
        //显示人员选择弹框
        add_manage(type){
            this.selectUserType = type
            this.visible_manage = true;	
        },
        //添加所选人员
        submit_manage({data}) {
            this.visible_manage = false;
            for(let i = 0; i < data.length; i++){
                let manageData = {id:data[i].id,username:data[i].username}
                if(this.selectUserType == 1){
                    this.factoryAll.push(manageData)
                }else if(this.selectUserType == 2){
                    this.qualityAll.push(manageData)
                }else if(this.selectUserType == 3){
                    this.produceAll.push(manageData)
                }else if(this.selectUserType == 4){
                    this.financeAll.push(manageData)
                }
				else if(this.selectUserType == 5){
				    this.warehouseAll.push(manageData)
				}
				
            }
        },
        //删除人员选择后的数据
        manage_delete(subscript,type){
            if(type == 1){
                let factoryAll = []
                this.factoryAll.forEach((item,index)=>{
                    if(subscript != index){
                        factoryAll.push(item)
                    }
                })
                this.factoryAll = factoryAll
            }else if(type == 2){
                let qualityAll = []
                this.qualityAll.forEach((item,index)=>{
                    if(subscript != index){
                        qualityAll.push(item)
                    }
                })
                this.qualityAll = qualityAll
            }else if(type == 3){
                let produceAll = []
                this.produceAll.forEach((item,index)=>{
                    if(subscript != index){
                        produceAll.push(item)
                    }
                })
                this.produceAll = produceAll
            } else if(type == 4){
                let financeAll = []
                this.financeAll.forEach((item,index)=>{
                    if(subscript != index){
                        financeAll.push(item)
                    }
                })
                this.financeAll = financeAll
            }
			else if(type == 5){
			    let warehouseAll = []
			    this.warehouseAll.forEach((item,index)=>{
			        if(subscript != index){
			            warehouseAll.push(item)
			        }
			    })
			    this.warehouseAll = warehouseAll
			}
        },
            
    }
}
</script>

<style lang="less" scoped>
.status_box{
    background:#1890ff;
    padding:1px 5px 3px 5px;
    color:#ffffff;
    border-radius:2px;
    font-size:12px;
    cursor:pointer;
    display:inline-block;
    min-width:34px;
}

.manage_main{
    margin-right: 10px;
    display:inline-block;

    .manage_user{
        border:1px #eeeeee solid;
        border-radius:5px;
        padding:5px;

        img{
            width:16px;
            margin-top:-3px;
            cursor: pointer;
        }
    }
}

</style>
